const statusColorTheme = {
  install: function (Vue) {
    Vue.prototype.$getStatusBackgroundColor = function (status) {
      let statusTheme = getTheme(status);
      return theme[statusTheme].backgroundColor;
    };

    Vue.prototype.$getStatusTextColor = function (status) {
      let statusTheme = getTheme(status);
      return theme[statusTheme].textColor;
    };

    Vue.prototype.$getStatusThemeName = function (status) {
      return getTheme(status);
    };
  },
};

const theme = {
  blueValue: {
    textColor: "#0C5AB4",
    backgroundColor: "#E1EBF6",
  },
  purpleValue: {
    textColor: "#AA228F",
    backgroundColor: "#F5E4F1",
  },
  lilacValue: {
    textColor: "#7153D5",
    backgroundColor: "#EDE6F9",
  },
  darkGrayValue: {
    textColor: "#616161",
    backgroundColor: "#E4E7EA",
  },
  orangeValue: {
    textColor: "#E87926",
    backgroundColor: "#FDEFE4",
  },
  darkOrangeValue: {
    textColor: "#BC621E",
    backgroundColor: "#FDEFE4",
  },
  redValue: {
    textColor: "#FF5252",
    backgroundColor: "#FAE5E5",
  },
  greenValue: {
    textColor: "#51680C",
    backgroundColor: "#E2E9D0",
  },
  lightGreenValue: {
    textColor: "#658210",
    backgroundColor: "#EAF1D8",
  },
  darkTealValue: {
    textColor: "#025E76",
    backgroundColor: "#DEE8EA",
  },
  blueGrayValue: {
    textColor: "#61778C",
    backgroundColor: "#EDF0F3",
  },
  lightValue: {
    textColor: "#BEBEBE",
    backgroundColor: "",
  },
  mediumGrayValue: {
    textColor: "#989898",
    backgroundColor: "#989898",
  },
};

const orangeValues = ["waitingForReview", "waitingForReissue"];

const darkOrangeValues = ["needsApproval"];

const blueGrayValues = ["submitted", "sending", "created"];

const blueValues = ["sent", "inProgress", "tsa"];

const purpleValues = ["waitingForParticipant"];

const lilacValues = ["created", "ira/tsa"];

const greenValues = ["complete", "cleared", "approved", "yes", "ira"];

const lightGreenValues = ["accepted"];

const darkTealValues = ["pending", "accountsDivision", "default", "new"];

const redValues = [
  "returned",
  "stopped",
  "denied",
  "no",
  "locked",
  "none",
  "errors",
];

const mediumGrayValues = ["notPayable"];

const lightValues = ["cancelled"];

const getTheme = function (status) {
  if (orangeValues.includes(status)) return "orangeValue";
  if (darkOrangeValues.includes(status)) return "darkOrangeValue";
  if (redValues.includes(status)) return "redValue";
  if (lightValues.includes(status)) return "lightValue";
  if (blueGrayValues.includes(status)) return "blueGrayValue";
  if (darkTealValues.includes(status)) return "darkTealValue";
  if (greenValues.includes(status)) return "greenValue";
  if (lightGreenValues.includes(status)) return "lightGreenValue";
  if (blueValues.includes(status)) return "blueValue";
  if (purpleValues.includes(status)) return "purpleValue";
  if (lilacValues.includes(status)) return "lilacValue";
  if (mediumGrayValues.includes(status)) return "mediumGrayValue";

  //If value doesn't match any option, it will apply darkGray color.

  return "darkGrayValue";
};

export default statusColorTheme;
