<template>
  <!-- use v-if="show" to init the modal when it is opened (v-if over v-show since that removes the modal from the DOM and adds it again on show) -->
  <v-dialog
    v-if="show"
    v-model="showing"
    :fullscreen="fullScreen"
    :max-width="maxWidth"
    :height="height"
    :persistent="persistent"
    :content-class="
      'singleton-modal ' + (draggable ? ' draggable ' : '') + contentClass
    "
    scrollable
    :class="modalClasses"
  >
    <v-card :class="{ 'pc-modal-card': true, 'pc-modal-spaced-columns': true }">
      <div
        v-if="withCatchBar"
        class="pc-modal-catch-bar"
        :style="
          'background-color:' +
          (catchBarColor ? catchBarColor : $colors.penChecksOrange) +
          ';'
        "
      ></div>
      <pc-button
        v-if="closable"
        text
        style="position: absolute; right: 5px; top: 10px"
        @click="close"
        class="pa-0"
        small
        :id="setId('CloseButton')"
      >
        <pc-icon
          name="pc-close-x"
          :color="$colors.penChecksMediumGray"
          size="26"
          light
        ></pc-icon>
      </pc-button>

      <v-card-title
        v-if="title"
        :id="setId('Title')"
        :class="{
          headline: true,
          'pc-modal-title-with-subtitle': subtitle,
          'd-flex justify-center': centerTitle,
        }"
        :style="'color: ' + $colors.penChecksDarkGray"
      >
        <span
          v-if="icon"
          :class="'mr-2 fas fa-' + icon"
          :style="'color:' + iconColor"
        ></span>
        <span class="pc-modal-title">{{ title }}</span>
        <div v-if="subtitle" class="pc-modal-subtitle">{{ subtitle }}</div>
      </v-card-title>

      <v-card-text
        style="max-height: 500px"
        class="px-10 pb-5"
        :class="{
          'pt-5': !title,
        }"
      >
        <pc-error-alert
          :api-response="apiResponse"
          :errors="errors"
          :error="error"
        ></pc-error-alert>

        <slot></slot>
      </v-card-text>

      <v-card-actions
        class="pc-modal-actions"
        :class="{
          'mx-auto': centerButtons,
        }"
      >
        <div>
          <slot name="modal-buttons-description"></slot>
        </div>
        <v-spacer></v-spacer>
        <div>
          <slot name="modal-buttons"></slot>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiResponse from "@/shared/lib/client-sdk/api-response";

export default {
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    iconColor: {
      type: String,
      required: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },

    draggable: {
      type: Boolean,
    },
    apiResponse: {
      type: ApiResponse,
    },
    errors: {
      type: Array,
    },
    error: {
      type: String,
    },
    size: {
      type: String,
      default: "normal",
    },
    idPrefix: {
      type: String,
    },
    height: {
      type: [String, Number],
      default: 500,
    },
    centerTitle: {
      type: Boolean,
      default: false,
    },
    centerButtons: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: String,
      default: undefined,
    },
    spacedCols: {
      type: Boolean,
      default: false,
    },
    modalClasses: {
      type: String,
      default: "",
    },
    closable: {
      type: Boolean,
      default: true,
    },
    withCatchBar: {
      type: Boolean,
      default: false,
    },
    catchBarColor: {
      type: String,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      maxWidth: 600,
      showing: false,
    };
  },

  watch: {
    show: function () {
      this.showing = this.show;
    },
    showing: function () {
      if (!this.showing && !this.persistent) {
        this.$emit("close");
      }
    },
    size: function () {
      this.setModalSize();
    },
  },

  methods: {
    close() {
      this.$emit("close");
      this.$emit("click:close");
    },
    setId: function (idSufix) {
      return this.idPrefix && idSufix ? this.idPrefix + idSufix : undefined;
    },
    setModalSize: function () {
      switch (this.size) {
        case "xxsmall":
          this.maxWidth = 352;
          break;
        case "xsmall":
          this.maxWidth = 432;
          break;
        case "small":
          this.maxWidth = 450;
          break;
        case "large":
          this.maxWidth = 750;
          break;
        case "okay":
          this.maxWidth = 888;
          break;
        case "xlarge":
          this.maxWidth = 950;
          break;
        case "xxlarge":
          this.maxWidth = 2048;
          break;
        default:
          this.maxWidth = 600;
      }
    },
  },

  computed: {},

  mounted: function () {
    this.showing = this.show;
    this.setModalSize();
  },
};
</script>

<style lang="scss">
.singleton-modal.draggable {
  .v-card__title {
    cursor: all-scroll !important;
  }
}

.singleton-modal {
  .v-card__title.pc-modal-title-with-subtitle {
    display: inline-block !important;
  }

  .v-card__title > .pc-modal-subtitle {
    font-size: 14px;
    margin-top: -10px;
  }
}

.pc-modal-card {
  .v-card__text {
    padding-left: 18px !important;
    padding-right: 18px !important;
    padding-bottom: 5px !important;
    max-height: 600px !important;
  }
}

.pc-modal-actions {
  padding-right: 17px;
  padding-bottom: 17px;
  margin-bottom: 8px;
}

.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}

.pc-modal-spaced-columns {
  .col {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}

.v-input--expand .v-input__slot {
  .v-label {
    display: block;
    flex: 1;
    font-size: 20px;
  }
}
.pc-modal-catch-bar {
  height: 6px;
}
</style>
