import { render, staticRenderFns } from "./PcClientApp.vue?vue&type=template&id=6e091b98"
import script from "./PcClientApp.vue?vue&type=script&lang=js"
export * from "./PcClientApp.vue?vue&type=script&lang=js"
import style0 from "./PcClientApp.vue?vue&type=style&index=0&id=6e091b98&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VApp})
