"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import Account from "@/shared/lib/client-sdk/models/account";
import DepositLink from "@/shared/lib/client-sdk/models/deposit-link";
import SearchRequestHandler from "@/shared/lib/client-sdk/search/search-request-handler";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import OrderLine from "@/shared/lib/client-sdk/models/order-line";
import Fee from "@/shared/lib/client-sdk/models/fee";
import $ from "jquery";

class Plan extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);

    this._isFetchingAssociateableOrderLines = false;
    this._associateableOrderLines = [];
  }

  static getModelClassName() {
    return "Plan";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") + "/public/plans"
    );
  }

  getApiPathForCreate() {
    if (
      !Object.prototype.hasOwnProperty.call(this, "accountId") ||
      this.accountId.length != 32
    )
      console.error("Cannot create a plan without an account id");

    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts/" +
      this.accountId +
      "/plans"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/plans/" +
      this.id
    );
  }

  getAdminPath() {
    return "/plans/" + this.id;
  }

  getClientPath() {
    return "/" + this.masterAccountId + "/plans/" + this.id;
  }

  get searchResultTitle() {
    return this.displayName;
  }

  // Returns a string, or an array of strings (for a multi-line subtitle)
  get searchResultSubtitle() {
    return "Plan Sponsor: " + this.accountDisplayName;
  }

  get searchResultCreatedAt() {
    return this.createdAt;
  }

  get searchResultStatus() {
    return this.status;
  }

  // If the API returns a relationship as a sub-object, this tells the base model which class to use for them when it laods it in.
  get relationships() {
    return {
      masterAccount: Account,
      subaccount: Account,
    };
  }

  get isActive() {
    return this.status === "active";
  }

  fetchOrderLinesForAssociation(isForRecurringOperation, callback) {
    if (this._isFetchingAssociateableOrderLines) {
      if ($.isFunction(callback)) callback();

      return;
    }

    this._isFetchingAssociateableOrderLines = true;

    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/trust/plans/" +
      this.id +
      "/order-lines-needing-association";

    if (isForRecurringOperation != null) {
      requestUrl += "?isForRecurringOperation=" + isForRecurringOperation;
    }

    ApiRequest.send("GET", requestUrl, null, OrderLine).onComplete(
      function (response) {
        this._isFetchingAssociateableOrderLines = false;

        if (response.successful) {
          this._associateableOrderLines = response.result;

          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  fetchPlanFundingSources(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/trust/plans/" +
      this.id +
      "/funding-sources";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  fetchDepositLinksWithNonassociatedAssets(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/plans/" +
      this.id +
      "/nonassociated-deposit-links";

    ApiRequest.send("GET", requestUrl, null, DepositLink).onComplete(
      function (response) {
        if (callback) {
          if (response.successful) {
            callback(response.result);
          } else {
            callback(false);
          }
        }
      }.bind(this)
    );
  }

  custodiansSearchHandler() {
    const searchHandler = new SearchRequestHandler("PlanCustodian");
    searchHandler.setRequestUrl(
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
        "/public/plans/" +
        this.id +
        "/custodians"
    );
    return searchHandler;
  }

  trusteesSearchHandler() {
    const searchHandler = new SearchRequestHandler("PlanTrustee");
    searchHandler.setRequestUrl(
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
        "/public/plans/" +
        this.id +
        "/trustees"
    );
    return searchHandler;
  }

  fundsSearchHandler() {
    const searchHandler = new SearchRequestHandler("DepositLink");
    searchHandler.setRequestUrl(
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
        "/public/deposit-links"
    );
    searchHandler.addPrivateFilter("planId", "equals", this.id);
    return searchHandler;
  }

  refreshFees(callback) {
    if (this.fees && this.fees.length > 0) callback(this.fees);

    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/plans/" +
      this.id +
      "/fees";

    ApiRequest.send("GET", requestUrl, null, Fee).onComplete(
      function (response) {
        if (callback) {
          if (response.successful) {
            this.fees = response.result;
            callback(response.result);
          } else {
            callback(false);
          }
        }
      }.bind(this)
    );
  }

  updateLogo(file, callback) {
    var requestUrl = this.getApiPathForRefreshUpdateAndDelete() + "/logo";

    let formData = new FormData();
    formData.append("file", file);

    ApiRequest.upload(requestUrl, formData, Plan).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  getLogo(width, height, callback) {
    var queryParams = width || height ? "?" : "";
    queryParams += width ? `width=${width}&` : "";
    queryParams += height ? `height=${height}` : "";

    var requestUrl =
      this.getApiPathForRefreshUpdateAndDelete() + `/logo${queryParams}`;

    console.log(requestUrl);

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  deleteLogo(callback) {
    var requestUrl = this.getApiPathForRefreshUpdateAndDelete() + "/logo";

    ApiRequest.send("DELETE", requestUrl, this).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  deactivate(callback) {
    var requestUrl = this.getApiPathForRefreshUpdateAndDelete() + "/deactivate";

    var payload = {
      planId: this.id,
    };

    ApiRequest.send("POST", requestUrl, payload, Plan).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  reactivate(callback) {
    var requestUrl = this.getApiPathForRefreshUpdateAndDelete() + "/reactivate";

    var payload = {
      planId: this.id,
    };

    ApiRequest.send("POST", requestUrl, payload, Plan).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  searchTaxForms(taxYear, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/plans/" +
      this.id +
      "/tax-forms?taxYear=" +
      taxYear;

    ApiRequest.send("GET", requestUrl, null).onComplete(function (response) {
      if (response.successful) {
        if ($.isFunction(callback)) {
          callback(response.result, null);
        }
      } else {
        if ($.isFunction(callback)) {
          callback(null, response);
        }
      }
    });
  }

  processTaxForms(forTaxFormSearchId, taxFormRequest, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/plans/" +
      this.id +
      "/tax-forms/" +
      forTaxFormSearchId;

    var requestBody = taxFormRequest;

    ApiRequest.send("POST", requestUrl, requestBody).onComplete(function (
      response
    ) {
      if (response.successful) {
        if ($.isFunction(callback)) {
          callback(response.result, null);
        }
      } else {
        if ($.isFunction(callback)) {
          callback(null, response);
        }
      }
    });
  }

  downloadTaxForms(forTaxFormSearchId, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/plans/" +
      this.id +
      "/tax-forms/" +
      forTaxFormSearchId +
      "/download";

    ApiRequest.send("GET", requestUrl).onComplete(function (response) {
      if (response.successful) {
        if ($.isFunction(callback)) {
          callback(response.result, null);
        }
      } else {
        if ($.isFunction(callback)) {
          callback(null, response);
        }
      }
    });
  }
}

export default Plan;
