<template>
  <div
    class="worksheet-section"
    v-show="
      showAlways ||
      selectedSectionIndex === 0 ||
      selectedSectionIndex === indexInParent + 1
    "
  >
    <hr
      size="1"
      v-if="selectedSectionIndex === 0 && indexInParent > 0"
      :color="$colors.penChecksLightGray"
      class="my-6"
    />

    <div style="padding-left: 36px; padding-right: 16px; padding-top: 2px">
      <template v-if="isWalkingThroughSections">
        <div class="text-right">
          <div class="mb-8">
            <pc-button
              outlined
              class="mr-2"
              :id="setId('UpperBackButton')"
              @click="walkthroughBackButtonClicked()"
              :disabled="isOnFirstWalkthroughSection"
              >BACK</pc-button
            >

            <pc-button
              :id="setId('UpperSaveButton')"
              @click="walkthroughContinueButtonClicked()"
            >
              <template v-if="isOnLastWalkthroughSection"
                >Save &amp; Finish</template
              >
              <template v-else>Save &amp; Continue</template>
            </pc-button>
          </div>
        </div>

        <pc-alert
          v-if="hasSlot('walkthrough-title')"
          type="announcement"
          class="mb-4"
        >
          <slot name="walkthrough-title"></slot>
        </pc-alert>
      </template>

      <h3>
        <span
          :id="setId('Title')"
          class="pr-4 pc-worksheet-section-title"
          v-if="showTitle"
          >{{ title }}</span
        >
        <div
          :id="setId('Subtitle')"
          class="section-subtitle ma-0 my-0"
          v-if="subtitle"
        >
          {{ subtitle }}
        </div>
        <span v-if="hasSlot('status')">
          <slot name="status"></slot>
        </span>
        <pc-status-chip v-if="status" v-model="status"></pc-status-chip>
        <span
          style="display: inline-block; margin-top: -3px"
          class="float-right text-right"
        >
          <template v-if="showButtons && (withEditButtons || buttonText)">
            <template v-if="buttonText">
              <pc-button
                text
                @click="customButtonClicked()"
                :disabled="buttonDisabled"
                :tooltip="buttonTooltip"
                :id="setId(buttonText.toPascalCase() + 'Button')"
              >
                <pc-icon :name="buttonIcon" size="12" class="mr-2"></pc-icon>
                {{ buttonText }}
              </pc-button>
            </template>
            <template v-if="withEditButtons && !isWalkingThroughSections">
              <pc-button
                v-if="editing"
                :color="$colors.penChecksMediumGray"
                text
                @click="cancelButtonClicked()"
                :id="setId('CancelButton')"
              >
                <pc-icon name="pc-cancel" size="14" class="mr-2"></pc-icon
                >CANCEL
              </pc-button>
              <pc-button
                :id="setId('SaveButton')"
                v-if="editing"
                text
                @click="saveButtonClicked()"
              >
                <pc-icon name="pc-save" size="14" class="mr-2"></pc-icon>SAVE
              </pc-button>
              <pc-button
                :id="setId('EditButton')"
                v-if="!editing"
                text
                @click="editButtonClicked()"
                :disabled="buttonDisabled"
                :tooltip="buttonTooltip"
              >
                <pc-icon name="pc-edit" size="14" class="mr-2"></pc-icon>EDIT
              </pc-button>
            </template>
          </template>
        </span>
      </h3>

      <div v-show="!editing || !hasSlot('editing')">
        <div class="observe-changes">
          <slot></slot>
        </div>
      </div>
      <div v-show="editing && hasSlot('editing')">
        <slot name="editing"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import getFeatureFlag from "@/shared/lib/getFeatureFlag";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
    },
    idPrefix: {
      type: String,
    },
    status: {
      type: String,
    },
    withEditButtons: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
    },
    buttonIcon: {
      type: String,
      default: "pc-edit",
    },
    buttonDisabled: {
      type: Boolean,
    },
    buttonTooltip: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    showAlways: {
      type: Boolean,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },

  data: function () {
    return {
      parentWorksheet: null,
      hasErrors: false,
      observer: null,

      walkthroughBackButtonClickHandler: null,
      walkthroughContinueButtonClickHandler: null,

      isDirtyHandler: null,

      startedEditingHandler: null,

      cancelButtonClickHandler: null,
      saveButtonClickHandler: null,
      customButtonClickHandler: null,

      editButtonActivated: false,

      currentTaxYearTooltip:
        "Digital 1099 requests can only be made for previous tax years. Please wait until the current year is processed or contact a PenChecks customer service representative.",
    };
  },

  watch: {
    editing: function () {
      if (this.editing && $.isFunction(this.startedEditingHandler))
        this.startedEditingHandler();
    },
  },

  computed: {
    selectedSectionIndex() {
      if (!this.parentWorksheet) return null;

      var parentSelectedSectionIndex =
        this.parentWorksheet.selectedSectionIndex;
      return parentSelectedSectionIndex;
    },
    indexInParent() {
      if (!this.parentWorksheet) return null;

      var currentSectionIndex = this.parentWorksheet.sections.indexOf(this);
      return currentSectionIndex;
    },
    isWalkingThroughSections() {
      if (!this.parentWorksheet) return false;

      return this.parentWorksheet.isWalkingThroughSections;
    },
    isOnFirstWalkthroughSection: function () {
      if (!this.parentWorksheet) return false;

      return this.parentWorksheet.isOnFirstWalkthroughSection;
    },
    isOnLastWalkthroughSection: function () {
      if (!this.parentWorksheet) return false;

      return this.parentWorksheet.isOnLastWalkthroughSection;
    },

    editing() {
      if (this.isWalkingThroughSections)
        return this.selectedSectionIndex == this.indexInParent + 1;

      return this.editButtonActivated;
    },
    showButtons() {
      if (!this.parentWorksheet) return true;

      return !this.parentWorksheet.noButtons;
    },
  },

  methods: {
    hasSlot(name) {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
    isWalkthroughSection() {
      return this.hasSlot("walkthrough-title");
    },
    registerWithParentWorksheet() {
      this.parentWorksheet = this.$parentWithTag("pc-worksheet");
      this.parentWorksheet.refreshChildren();
    },
    contentChanged() {
      var errorDivs = $(this.$el).find(".has-error");
      this.hasErrors = errorDivs.length > 0;
    },

    walkthroughBackButtonClicked() {
      if ($.isFunction(this.walkthroughBackButtonClickHandler))
        this.walkthroughBackButtonClickHandler();
      else if (this.parentWorksheet) this.moveToPreviousWalkthroughStep();
    },
    moveToPreviousWalkthroughStep() {
      this.parentWorksheet.walkthroughStepIndex--;
    },
    walkthroughContinueButtonClicked() {
      if ($.isFunction(this.walkthroughSaveButtonClickHandler))
        this.walkthroughSaveButtonClickHandler();
      else if (this.parentWorksheet) this.moveToNextWalkthroughStep();
    },
    moveToNextWalkthroughStep() {
      this.parentWorksheet.walkthroughStepIndex++;
    },

    editButtonClicked() {
      if (!this.parentWorksheet) return;

      this.parentWorksheet.sectionStartedEditing(this);
      this.editButtonActivated = true;
      this.$emit("edit");
    },

    cancelButtonClicked() {
      if ($.isFunction(this.cancelButtonClickHandler))
        this.cancelButtonClickHandler();
      else this.cancelComplete();
    },
    cancelComplete() {
      if (!this.parentWorksheet) return;

      this.parentWorksheet.sectionEndedEditing(this);
      this.editButtonActivated = false;
      this.$emit("cancel");
    },

    saveButtonClicked() {
      if ($.isFunction(this.saveButtonClickHandler))
        this.saveButtonClickHandler();
      else this.saveComplete();
    },
    saveComplete() {
      if (!this.parentWorksheet) return;

      this.parentWorksheet.sectionEndedEditing(this);
      this.$emit("save");
      this.editButtonActivated = false;
    },

    nextWalkthroughStepOrSaveComplete() {
      if (this.isWalkingThroughSections) {
        this.moveToNextWalkthroughStep();
      } else {
        this.saveComplete();
      }
    },

    customButtonClicked() {
      if ($.isFunction(this.customButtonClickHandler)) {
        this.customButtonClickHandler();
      } else if (this.$listeners && this.$listeners["button-click"]) {
        this.$emit("button-click");
      } else {
        this.$emit("click");
      }
    },

    isDirty() {
      if (this.isDirtyHandler && $.isFunction(this.isDirtyHandler))
        return this.isDirtyHandler();

      return false;
    },

    setId: function (idSufix) {
      return this.idPrefix && idSufix ? this.idPrefix + idSufix : undefined;
    },
  },

  mounted: function () {
    const config = { attributes: true, childList: true, subtree: true };
    this.observer = new MutationObserver(this.contentChanged.bind(this));

    var observeDiv = $(this.$el).find(".observe-changes")[0];
    this.observer.observe(observeDiv, config);

    this.registerWithParentWorksheet();
  },

  beforeDestroy: function () {
    this.observer.disconnect();
  },

  destroyed: function () {
    if (this.parentWorksheet) this.parentWorksheet.refreshChildren();
  },
};
</script>

<style lang="scss">
.worksheet-section {
  h3 {
    button {
      font-size: 12px !important;
      font-weight: 500;
    }
  }
  .section-subtitle {
    color: $penChecksMediumGray !important;
    font-size: 14px !important;
    margin-left: 14px;
  }
}
</style>
