<template>
  <div
    :class="
      isParticipantPortalField
        ? 'text-field is-participant-portal-field'
        : 'text-field is-general-field'
    "
  >
    <div
      v-if="isParticipantPortalField && showHeader"
      class="text-field-header-style"
    >
      <span v-if="required && !isAccountHolderField" class="error-text">*</span
      >{{ header }}
    </div>
    <v-text-field
      :id="id"
      v-model="text"
      :rules="
        required ? textRules.concat(printabilityRules) : printabilityRules
      "
      :type="type"
      :label="
        isParticipantPortalField
          ? ''
          : showLabel
          ? label + (required || hideOptionalText ? '' : ' (optional)')
          : ''
      "
      :placeholder="placeholder"
      :validate-on-blur="validateBlur"
      :disabled="disabled"
      :clearable="clearable"
      :dense="dense"
      :maxLength="maxLength"
      :errorMessages="errors"
      :color="$colors.penChecksTeal"
      :hint="hint"
      :persistent-hint="persistentHint"
      :append-icon="appendIcon"
      v-on="$listeners"
      @keyup.enter="$emit('enter', $event)"
      @change="$emit('change', text)"
      @click:clear="$emit('clear')"
      @blur="handleBlur"
      @focus="errors = null"
      ref="textfield"
      :outlined="isParticipantPortalField"
    ></v-text-field>
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
    },
    appendIcon: {
      type: String,
    },
    id: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    errorMessages: {
      type: Array,
    },
    dense: {
      type: Boolean,
    },
    noPostOfficeBoxes: {
      type: Boolean,
    },
    printableCharsValidation: {
      type: Boolean,
    },
    hint: {
      type: String,
    },
    persistentHint: {
      type: Boolean,
    },
    hideOptionalText: {
      type: Boolean,
    },
    maxLength: {
      type: Number,
    },
    isVerificationCode: {
      type: Boolean,
      default: false,
    },
    isParticipantPortalField: {
      type: Boolean,
      default: false,
    },
    isAccountHolderField: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: "",
    },
    errorLabel: {
      type: String,
      default: "",
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    validateBlur: {
      type: Boolean,
      default: true,
    },
  },

  data: function () {
    return {
      text: this.value, // If rules get much more complex, replace :rules= ternary operators with a computed function that determines which rulesets to apply
      textRules: [
        () => {
          if (this.required && (!this.text || this.text == ""))
            return this.isAccountHolderField
              ? this.errorLabel
              : "Field cannot be left blank";
          return true;
        },
        () => {
          if (this.isVerificationCode && text.length != 6)
            return "Your code should be composed of six numbers";
          return true;
        },
        () => {
          if (!this.text || this.text == "" || !this.noPostOfficeBoxes)
            return true;

          // Looks for [BOXWORD][NUMBER IDENTIFIER #][DIGITS]
          // P.O.Box 123
          // POBox # 123
          // P/OBox. 123
          // OfficeBox Number 123
          // PostOfficeBox Num. 123
          // Box 123

          var poBox =
            /\b(P\s*\.?\/?\s*O|OFFICE|POSTOFFICE)?\s*\.?\s*BOX\s*\.?\s*(num(ber)?|#)?\s*\.?\s*\d+/i;

          if (this.text.match(poBox))
            return "Post office box is not allowed. Please enter a physical address.";

          return true;
        },
      ],
      printabilityRules: [
        (v) => {
          if (this.printableCharsValidation) {
            const PRINTABLE_CHARS_REGEX =
              /[^ !"#$%^&'()*+,\-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\]_`abcdefghijklmnopqrstuvwxyz{|}~¡¢£¥¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿ]+/;
            if (PRINTABLE_CHARS_REGEX.test(v)) {
              return "An invalid character was found in this field. Please check your entry and try again.";
            }
            return true;
          }
          return true;
        },
      ],
      errors: null,
    };
  },
  methods: {
    handleBlur() {
      this.$emit("input", this.value !== null && this.value.trim());
      this.$emit("blur");
    },
  },
  watch: {
    text(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      if (this.text != newValue) {
        this.text = newValue;
      }
    },
    errorMessages(errors) {
      this.errors = errors;
    },
  },

  mounted: function () {
    this.$emit("mounted");
  },
};
</script>

<style lang="scss">
.text-field.is-participant-portal-field {
  .text-field-header-style {
    margin-left: 10px;
    margin-bottom: 8px;
    font-family: "Open Sans", sans-serif;
    color: $neutral70;
    font-size: 12px;
    font-weight: 600;
  }

  .v-input--is-focused {
    .v-input__slot {
      border-width: 1px !important;
      border-color: $primary !important;
      box-shadow: 0px 0px 0px 3px $primary10 !important;
    }
  }

  div.v-input:not(.error--text, .v-input--is-focused) {
    fieldset {
      border-color: $neutral10 !important;
    }
  }

  div.v-text-field {
    border-radius: 6px !important;
  }

  div.v-text-field__slot {
    margin-top: auto;
    margin-bottom: auto;
    height: 20px;
    font-family: "Open Sans", sans-serif;
    input {
      color: $neutral70 !important;
      font-size: 14px;
      height: 20px !important;
    }

    input::placeholder {
      font-family: "Open Sans", sans-serif !important;
      color: $neutral30 !important;
      font-weight: 400 !important;
      font-size: 14px;
    }
  }

  .v-input__slot {
    min-height: 40px !important;
    height: 20px;
  }

  .v-input--is-focused {
    .v-input__slot {
      color: $primary !important;
      caret-color: $primary !important;
      box-shadow: 0px 0px 0px 3px $primary10 !important;
      fieldset {
        border-width: 1px !important;
      }
    }
  }
}
</style>
